import { useTranslation } from "react-i18next";

const AbandonedView = () => {
  const { t } = useTranslation();
  return (
    <div className={"mt-[calc(20px+env(safe-area-inset-top))]"}>
      <img src="/logo.gif" alt="TRADEFOOX" className="mb-8" />
      <div className="px-5 text-center">
        <h1>{t("main.maintained")}</h1>
        <div className="flex flex-col items-center space-y-5 pt-10">
          <a href="https://apps.apple.com/de/app/tradefoox/id6477870055">
            <img src="/images/iOS.png" alt="iOS" className="mb-2" />
            <p>iOS</p>
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.tradefoox.mobile&gl=DE">
            <img src="/images/android.png" alt="android" className="mb-2" />
            <p>Android</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AbandonedView;
