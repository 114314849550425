import {
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconSolid } from "@heroicons/react/24/solid";
import SharePopup from "@src/components/popup/SharePopup";
import PostsActionBarItem from "@src/pages/news/posts/PostsActionBarItem";
import PostBookmarkButton from "@src/pages/news/posts/actions/PostBookmarkButton";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  toggleComments: () => void;
  toggleLike: () => void;
  commentCount: number;
  likeCount: number;
  viewCount: number;
  bookmarkCount: number;
  repostCount: number;
  bookmarked: boolean;
  isReposted: boolean;
  postId: string;
  isLiked: boolean;
  dark?: boolean;
  fullscreen?: boolean;
};

const PostsActionBar = (props: Props) => {
  const [sharePopupOpen, setSharePopupOpen] = React.useState(false);
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);
  const [likeEffect, setLikeEffect] = useState(false);
  const { t } = useTranslation();

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div
      className={`relative mt-2 flex h-6 flex-row ${props.fullscreen ? "justify-center gap-x-5" : "justify-between"}  text-darkblue ${props.fullscreen && "px-3 max-md:w-screen"}`}
    >
      <PostsActionBarItem
        onClick={props.toggleComments}
        label={t("components.posts.answer")}
        count={props.commentCount}
        textColor={
          props.dark
            ? "text-white group-hover:text-white"
            : "text-gray-600 group-hover:text-blue-700"
        }
        color={props.dark ? "group-hover:bg-blue-900" : "group-hover:bg-blue-100"}
      >
        <ChatBubbleOvalLeftIcon />
      </PostsActionBarItem>
      <PostsActionBarItem
        label={t("pages.post.sharePost")}
        count={props.repostCount}
        onClick={() => checkAuth() && setSharePopupOpen(true)}
        textColor={
          props.isReposted
            ? props.dark
              ? "text-green-200"
              : "text-green-700"
            : props.dark
              ? "text-white group-hover:text-white"
              : "text-gray-600 group-hover:text-green-700"
        }
        color={
          props.isReposted
            ? props.dark
              ? "bg-green-900"
              : "bg-green-100"
            : props.dark
              ? "group-hover:bg-green-900"
              : "group-hover:bg-green-100"
        }
      >
        <ArrowPathRoundedSquareIcon />
      </PostsActionBarItem>
      <PostsActionBarItem
        label={t("buttons.like")}
        count={props.likeCount}
        onClick={() => checkAuth() && props.toggleLike()}
        textColor={
          props.isLiked
            ? props.dark
              ? "text-pink-200"
              : "text-red-600"
            : props.dark
              ? "text-white group-hover:text-white"
              : "text-gray-600 group-hover:text-red-600"
        }
        color={
          props.isLiked
            ? props.dark
              ? "bg-pink-900"
              : "bg-pink-100"
            : props.dark
              ? "group-hover:bg-pink-900"
              : "group-hover:bg-pink-100"
        }
      >
        {props.isLiked ? (
          <HeartIconSolid className={`${likeEffect && "mb-1 animate-ping-once"}`} />
        ) : (
          <HeartIcon
            onClick={() => {
              setLikeEffect(true);
            }}
            onAnimationEnd={() => {
              setLikeEffect(false);
            }}
          />
        )}
      </PostsActionBarItem>
      <PostsActionBarItem
        label="Views"
        count={props.viewCount}
        textColor={
          props.dark
            ? "text-white group-hover:text-white"
            : "text-gray-600 group-hover:text-blue-700"
        }
        color={props.dark ? "group-hover:bg-blue-900" : "group-hover:bg-blue-100"}
      >
        <EyeIcon />
      </PostsActionBarItem>
      <PostBookmarkButton
        postId={props.postId}
        bookmarked={props.bookmarked}
        bookmarkCount={props.bookmarkCount}
        textColor={
          props.dark
            ? `${props.bookmarked ? "text-[#facc15] group-hover:text-[#facc15]" : "text-white group-hover:text-white"} `
            : "text-gray-600 group-hover:text-slate-700"
        }
        color={props.dark ? "group-hover:bg-slate-900" : "group-hover:bg-slate-200"}
      />
      {sharePopupOpen && (
        <SharePopup
          postId={props.postId}
          content={{
            title: "News",
            body: new URL(`/s/p/${props.postId}`, window.location.href).toString(),
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
    </div>
  );
};

export default PostsActionBar;
