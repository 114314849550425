import {
  ArrowPathRoundedSquareIcon,
  ChatBubbleOvalLeftIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  HeartIcon,
  NewspaperIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import UserDataClubForm from "@src/components/elements/form/UserDataClubForm";
import UserDataEmployeeForm from "@src/components/elements/form/UserDataEmployeeForm";
import Dropdown from "@src/components/menu/Dropdown";
import DropdownItem from "@src/components/menu/DropdownItem";
import ClubContainer from "@src/pages/profile/details/ClubContainer";
import CompanyAndClubProfileContainer from "@src/pages/profile/details/CompanyAndClubProfileContainer";
import EmployeeContainer from "@src/pages/profile/details/EmployeeContainer";
import GoogleMapsWidget from "@src/pages/profile/details/GoogleMapsWidget";
import PrivateContainer from "@src/pages/profile/details/PrivateContainer";
import PrivateProfileContainer from "@src/pages/profile/details/PrivateProfileContainer";
import ProfileDetailsDescriptionContainer from "@src/pages/profile/details/ProfileDetailsDescriptionContainer";
import { User } from "@src/utils/user";
import { TFunction } from "i18next";
import { SocialIcon } from "react-social-icons";

const socialmediaTypes = [
  { name: "Twitter", value: "twitter", url: "https://twitter.com/" },
  { name: "Facebook", value: "facebook", url: "https://facebook.com/" },
  { name: "Instagram", value: "instagram", url: "https://instagram.com/" },
  { name: "Tiktok", value: "tiktok", url: "https://tiktok.com/" },
  { name: "Youtube", value: "youtube", url: "https://youtube.com/" },
  { name: "Linkedin", value: "linkedin", url: "https://linkedin.com/" },
  { name: "Xing", value: "xing", url: "https://xing.com/" },
];

export type SidebarConfigType = {
  type?: string;
  name?: string;
  title?: string;
  items?: {
    type: string;
    property?: string;
    placeholder?: string;
  }[];
};

export type ProfileConfigType = {
  [key in "company" | "person" | "club"]: {
    tabs: { name: string; key: string; url: string }[];
    mediatabs: { name: string; key: string; icon: JSX.Element }[];
    userlabels: {
      items: {
        label: string;
        type: string;
        property: Array<string>;
        placeholder: string;
      }[];
    };
    userbuttons: {
      type: string;
      buttons: {
        type: string;
        action?: string;
        image?: JSX.Element;
        property?: string;
        color?: string;
        menupoints?: {
          label: string;
          fn: () => void;
          requireAuth?: boolean;
        }[];
      }[];
    };
    sidebarbuttons: {
      title: string;
      buttons: {
        image: string;
        tabname: string;
        label: string;
        authRequired?: boolean;
        page?: string;
        state?: Record<string, string>;
        props?: { state: Record<string, string> };
      }[];
    };
    sidebars: SidebarConfigType[];
  };
};

const ProfileConfig = (t: TFunction): ProfileConfigType => ({
  person: {
    tabs: [
      { name: "Über mich", key: "about", url: "über_mich" },
      { name: "Feed", key: "news", url: "news" },
      { name: "Watch", key: "watch", url: "watch" },
      { name: "Jobs", key: "jobs", url: "jobs" },
      { name: "Shop", key: "ads", url: "shop" },
      { name: "Inserate", key: "assignments", url: "inserate" },
    ],
    mediatabs: [
      {
        name: "Feed",
        key: "news",
        icon: <NewspaperIcon className="size-4" />,
      },
      {
        name: "Watch",
        key: "watch",
        icon: <VideoCameraIcon className="size-4" />,
      },
    ],
    userlabels: {
      items: [
        {
          label: t("components.shared.friends"),
          type: "objectlabel",
          property: ["friends"],
          placeholder: "0",
        },
        {
          label: t("main.contacts.tabs.likes"),
          type: "objectlabel",
          property: ["likes"],
          placeholder: "0",
        },
        {
          label: t("main.navLinks.createAd.tabs.follower"),
          type: "objectlabel",
          property: ["follower_count"],
          placeholder: "0",
        },
        {
          label: t("main.follows.title"),
          type: "objectlabel",
          property: ["following_count"],
          placeholder: "0",
        },
      ],
    },
    userbuttons: {
      type: "userbuttons",
      buttons: [
        {
          type: "button",
          action: "message",
          image: <ChatBubbleOvalLeftIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
        },
        {
          type: "button",
          action: "share",
          image: (
            <ArrowPathRoundedSquareIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
          ),
          property: "sharedata",
        },
        {
          type: "button",
          action: "like",
          image: <HeartIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
          property: "like_count",
        },
        {
          type: "button",
          image: <EyeIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
          property: "views",
        },
        {
          type: "dropdown",
          image: <EllipsisHorizontalIcon className="heroicon-stroke-w-2.0 size-5 text-darkblue" />,
          menupoints: [
            {
              label: t("buttons.connect"),
              fn: () => {
                console.log("network");
              },
            },
            {
              label: t("main.shop.buttons.contact"),
              fn: () => {
                console.log("contact");
              },
            },
            {
              label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
              fn: () => {
                console.log("edit");
              },
              requireAuth: true,
            },
            {
              label: t("buttons.share"),
              fn: () => {
                console.log("share");
              },
            },
            {
              label: t("buttons.report"),
              fn: () => {
                console.log("report");
              },
            },
            {
              label: t("buttons.like"),
              fn: () => {
                console.log("like");
              },
            },
          ],
        },
      ],
    },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.furtherInfo"),
      buttons: [
        {
          image: "/images/assignmentsfile.svg",
          tabname: "edit",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
          authRequired: true,
          page: "/verwaltung/profile",
          state: { backlink: "/profile" },
        },
        {
          image: "/images/contact_person.svg",
          tabname: "contacts",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.contacts"),
        },
        {
          image: "/images/steckbrief.svg",
          tabname: "other_facts",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.characteristics"),
        },
        {
          image: "/images/cv.svg",
          tabname: "cvs",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.career"),
        },
        {
          image: "/images/media_images.svg",
          tabname: "media",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.media"),
        },
        {
          image: "/images/hashtag.svg",
          tabname: "hashtags",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.hashtags"),
        },
        {
          image: "/images/pricetag.svg",
          tabname: "assignments",
          label: t("main.profileViewTabs.advertisements.title"),
        },
        {
          image: "/images/media_library.svg",
          tabname: "social_media",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.socialMedia"),
        },
        {
          image: "/images/statistics.svg",
          tabname: "stats",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.statistics"),
          authRequired: true,
        },
      ],
    },
    sidebars: [
      {
        type: "userdescription",
      },
      {
        name: "contacts",
        title: "Kontakte",
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
      {
        name: "other_facts",
        title: "Steckbrief",
        items: [
          {
            type: "other_facts",
          },
        ],
      },
    ],
  },
  club: {
    tabs: [
      { name: t("components.shared.aboutUs"), key: "about", url: "über_uns" },
      { name: t("main.profileViewTabs.feed.title"), key: "news", url: "news" },
      {
        name: t("main.profileViewTabs.watch.title"),
        key: "watch",
        url: "watch",
      },
      { name: t("main.profileViewTabs.jobs.title"), key: "jobs", url: "jobs" },
      { name: t("main.profileViewTabs.shop.title"), key: "ads", url: "shop" },
      {
        name: t("main.profileViewTabs.advertisements.title"),
        key: "assignments",
        url: "inserate",
      },
    ],
    mediatabs: [
      {
        name: "Feed",
        key: "news",
        icon: <NewspaperIcon className="size-4" />,
      },
      {
        name: "Watch",
        key: "watch",
        icon: <VideoCameraIcon className="size-4" />,
      },
    ],
    userlabels: {
      items: [
        {
          label: t("main.contacts.tabs.likes"),
          type: "objectlabel",
          property: ["likes"],
          placeholder: "0",
        },
        {
          label: t("main.contacts.tabs.followers"),
          type: "objectlabel",
          property: ["follower_count"],
          placeholder: "0",
        },
      ],
    },
    userbuttons: {
      type: "userbuttons",
      buttons: [
        {
          type: "button",
          action: "message",
          image: <ChatBubbleOvalLeftIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
        },
        {
          type: "button",
          action: "share",
          image: (
            <ArrowPathRoundedSquareIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
          ),
          property: "sharedata",
        },
        {
          type: "button",
          action: "like",
          image: <HeartIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
          property: "like_count",
        },
        {
          type: "button",
          image: <EyeIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
          property: "views",
        },
        {
          type: "dropdown",
          image: <EllipsisHorizontalIcon className="heroicon-stroke-w-2.0 size-5 text-darkblue" />,
          menupoints: [
            {
              label: t("main.shop.buttons.contact"),
              fn: () => {
                console.log("contact");
              },
            },
            {
              label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
              fn: () => {
                console.log("edit");
              },
              requireAuth: true,
            },
            {
              label: t("buttons.share"),
              fn: () => {
                console.log("share");
              },
            },
            {
              label: t("buttons.like"),
              fn: () => {
                console.log("like");
              },
            },
            {
              label: t("buttons.report"),
              fn: () => {
                console.log("report");
              },
            },
          ],
        },
      ],
    },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.furtherInfo"),
      buttons: [
        {
          image: "/images/assignmentsfile.svg",
          tabname: "edit",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
          authRequired: true,
          page: "/verwaltung/profile",
          props: { state: { backlink: "/profile" } },
        },
        {
          image: "/images/contact_person.svg",
          tabname: "contacts",
          label: t("main.profileTabs.company.contact"),
        },
        {
          image: "/images/data.svg",
          tabname: "facts",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.facts"),
        },
        {
          image: "/images/worker.svg",
          tabname: "employee",
          label: t("main.profileViewTabs.editProfile.form.employees"),
        },
        {
          image: "/images/members.svg",
          tabname: "members",
          label: t("main.members.title"),
        },
        {
          image: "/images/media_images.svg",
          tabname: "media",
          label: t("main.feedTabs.feetCreate.media"),
        },
        {
          image: "/images/hashtag.svg",
          tabname: "hashtags",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.hashtags"),
        },
        {
          image: "/images/jobsfile.svg",
          tabname: "jobs",
          label: t("main.header.links.jobs"),
        },
        {
          image: "/images/pricetag.svg",
          tabname: "assignments",
          label: t("main.profileViewTabs.advertisements.title"),
        },
        {
          image: "/images/media_library.svg",
          tabname: "social_media",
          label: t("main.profileTabs.person.socialMedia"),
        },
        {
          image: "/images/statistics.svg",
          tabname: "stats",
          label: t("main.contacts.tabs.statistics"),
          authRequired: true,
        },
      ],
    },
    sidebars: [
      {
        type: "userdescription",
      },
      {
        name: "facts",
        title: t("components.shared.dataAndFacts"),
        items: [
          {
            type: "other_facts",
          },
        ],
      },
      {
        name: "contacts",
        title: t("main.header.links.contacts"),
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
    ],
  },
  company: {
    tabs: [
      { name: t("components.shared.aboutUs"), key: "about", url: "über_uns" },
      { name: t("main.navLinks.news"), key: "news", url: "news" },
      { name: t("main.navLinks.watch"), key: "watch", url: "watch" },
      { name: t("main.header.links.jobs"), key: "jobs", url: "jobs" },
      { name: t("main.profileViewTabs.shop.title"), key: "ads", url: "shop" },
      {
        name: t("main.profileViewTabs.advertisements.title"),
        key: "assignments",
        url: "inserate",
      },
    ],
    mediatabs: [
      {
        name: t("main.profileViewTabs.watch.title"),
        key: "watch",
        icon: <VideoCameraIcon className="size-4" />,
      },
      {
        name: t("main.profileViewTabs.feed.title"),
        key: "news",
        icon: <NewspaperIcon className="size-4" />,
      },
    ],
    userlabels: {
      items: [
        {
          label: t("main.contacts.tabs.likes"),
          type: "objectlabel",
          property: ["likes"],
          placeholder: "0",
        },
        {
          label: t("main.contacts.tabs.followers"),
          type: "objectlabel",
          property: ["follower_count"],
          placeholder: "0",
        },
      ],
    },
    userbuttons: {
      type: "userbuttons",
      buttons: [
        {
          type: "button",
          action: "message",
          image: <ChatBubbleOvalLeftIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
        },
        {
          type: "button",
          action: "share",
          image: (
            <ArrowPathRoundedSquareIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />
          ),
          property: "sharedata",
        },
        {
          type: "button",
          action: "like",
          image: <HeartIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
          property: "like_count",
        },
        {
          type: "button",
          image: <EyeIcon className="heroicon-stroke-w-2.0 size-3 text-darkblue" />,
          property: "views",
        },
        {
          type: "dropdown",
          image: <EllipsisHorizontalIcon className="heroicon-stroke-w-2.0 size-5 text-darkblue" />,
          menupoints: [
            {
              label: t("main.shop.buttons.contact"),
              fn: () => {
                console.log("contact");
              },
            },
            {
              label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
              fn: () => {
                console.log("edit");
              },
              requireAuth: true,
            },
            {
              label: t("buttons.share"),
              fn: () => {
                console.log("share");
              },
            },
            {
              label: t("buttons.like"),
              fn: () => {
                console.log("like");
              },
            },
            {
              label: t("buttons.report"),
              fn: () => {
                console.log("report");
              },
            },
          ],
        },
      ],
    },
    sidebarbuttons: {
      title: t("main.profileViewTabs.aboutMe.content.furtherInfo"),
      buttons: [
        {
          image: "/images/assignmentsfile.svg",
          tabname: "edit",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit"),
          authRequired: true,
          page: "/verwaltung/profile",
          state: { backlink: "/profile" },
        },
        {
          image: "/images/contact_person.svg",
          tabname: "contacts",
          label: t("main.profileTabs.company.contact"),
        },
        {
          image: "/images/data.svg",
          tabname: "facts",
          label: t("main.profileViewTabs.aboutMe.content.sidebarButtons.facts"),
        },
        {
          image: "/images/worker.svg",
          tabname: "employee",
          label: t("main.profileViewTabs.editProfile.form.employees"),
        },
        {
          image: "/images/jobsfile.svg",
          tabname: "jobs",
          label: t("main.header.links.jobs"),
        },
        {
          image: "/images/cv.svg",
          tabname: "cvs",
          label: t("main.jobsTabs.career"),
          authRequired: true,
        },
        {
          image: "/images/pricetag.svg",
          tabname: "assignments",
          label: t("main.profileViewTabs.advertisements.title"),
        },
        {
          image: "/images/media_images.svg",
          tabname: "media",
          label: t("main.feedTabs.feetCreate.media"),
        },
        {
          image: "/images/hashtag.svg",
          tabname: "hashtags",
          label: t("main.bookmarks.tabs.hashtags"),
        },
        {
          image: "/images/media_library.svg",
          tabname: "social_media",
          label: t("main.profileTabs.person.socialMedia"),
        },
        {
          image: "/images/statistics.svg",
          tabname: "stats",
          label: t("main.navLinks.createAd.tabs.statistics"),
          authRequired: true,
        },
      ],
    },
    sidebars: [
      {
        type: "userdescription",
      },
      {
        name: "facts",
        title: t("components.shared.dataAndFacts"),
        items: [
          {
            type: "other_facts",
          },
        ],
      },
      {
        name: "contacts",
        title: t("main.header.links.contacts"),
        items: [
          {
            type: "contactbox",
          },
          {
            type: "googlemaps",
          },
        ],
      },
    ],
  },
});

/**
 * Returns component according to type
 * @param {any} component
 * @param {User} user
 * @returns
 */
export function getComponent({ component, user, t }: { component: any; user?: User; t: any }) {
  console.log(component.type + "dsd");
  switch (component.type) {
    case "functionlabel":
      if (!component.fn) return;
      if (!user) return;
      if (!getProperty(user, component.property, component.placeholder)) return;

      return (
        <div
          className={`flex flex-wrap gap-1 ${component.bold && "font-semibold"} ${component.small && "text-xs"}`}
        >
          {component.label && <div>{component.label}</div>}
          {component.fn(getProperty(user, component.property, component.placeholder))}
        </div>
      );

    case "userbuttons":
      return (
        <div className="flex flex-wrap gap-2 text-xs">
          {component.buttons?.map((button: any, index: number) => {
            switch (button.type) {
              case "button":
                return (
                  <button
                    key={index}
                    className={`flex h-[20px] w-[62px] flex-row items-center justify-center gap-1 rounded-lg ${
                      button.color ? button.color : "bg-slate-200"
                    }`}
                    onClick={() => {
                      console.log(button.action);
                    }}
                  >
                    {button.image}
                    <div key={index} className="text-gray-500">
                      {button?.label} {button.property && "0"}
                    </div>
                  </button>
                );

              case "dropdown":
                return (
                  <div
                    className={`flex h-[20px] w-[62px] flex-row justify-center gap-1 rounded-lg ${
                      button.color ? button.color : "bg-slate-200"
                    }`}
                  >
                    <Dropdown icon={button.image} align="left">
                      {button.menupoints?.map((menupoint: any) => {
                        return (
                          <DropdownItem
                            key={menupoint.label}
                            onClick={menupoint.fn}
                            className="w-56 px-4 py-2 text-left text-sm text-gray-900 hover:bg-gray-100"
                          >
                            {menupoint.label}
                          </DropdownItem>
                        );
                      })}
                    </Dropdown>
                  </div>
                );

              default:
                return <div></div>;
            }
          })}
        </div>
      );

    case "objectlabel":
      if (!user) return;
      /*        if (!getProperty(user, component.property, component.placeholder)) return; // show placeholder if property is empty or undefined
       */
      return (
        <div className="flex flex-wrap gap-1">
          {component.label && (
            <div className={`${component.bold && "font-semibold"}`}>{component.label}</div>
          )}
          {getProperty(user, component.property, component.placeholder)}
        </div>
      );

    case "socialmedia":
      if (!user) return;
      if (!getProperty(user, component.property)) return;

      return (
        <div className="flex flex-wrap gap-2 ">
          {getProperty(user, component.property, component.placeholder).map(
            (socialmedia: any, index: number) => {
              return (
                <div key={index}>
                  <SocialIcon url={getSocialMedia(socialmedia)} className="-mx-2 scale-75" />
                </div>
              );
            },
          )}
        </div>
      );

    case "googlemaps":
      if (!user) return;
      return <GoogleMapsWidget />;

    case "contactbox":
      if (!user) return;
      return <div className="grid grid-cols-2 gap-4 md:grid-cols-3">{getContactBox(user)}</div>;

    case "userdescription":
      if (!user) return;
      return (
        <>
          <div className="mb-2 ml-2">
            <ProfileDetailsDescriptionContainer>
              <UserDataEmployeeForm user={user} formType="basic" />
            </ProfileDetailsDescriptionContainer>
          </div>
        </>
      );

    case "userClubDescription":
      if (!user) return;
      return (
        <>
          <div className="mb-2 ml-2">
            <ProfileDetailsDescriptionContainer>
              <UserDataClubForm user={user} formType="basic" />
            </ProfileDetailsDescriptionContainer>
          </div>
        </>
      );

    case "other_facts":
      if (!user) return;
      return (
        <>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3">{getProfileBox(user)}</div>
        </>
      );
    default:
      return <div>{t("components.shared.unknownComponent")}</div>;
  }
}

/**
 * Returns the value of the property of the JSON object
 * @param object JSON object
 * @param path properties
 * @param placeholder returns placeholder if value of property is undefined
 * @returns value of property. returns undefined if no value was found
 */
const getProperty = (object: any, path: Array<string>, placeholder?: string) => {
  let currentValue = object;
  for (const element of path) {
    if (!element) return object;
    if (!currentValue[element]) return placeholder;
    currentValue = JSON.parse(JSON.stringify(currentValue[element]));
  }
  return currentValue;
};

/**
 * Returns the url of the social media
 * @param linkData Linkdata of the social media page
 * @returns url of social media
 */
const getSocialMedia = (linkData: any) => {
  const index = socialmediaTypes.findIndex(element => element.value === linkData.type);
  if (index < 0) return undefined;
  return socialmediaTypes[index].url + linkData.username;
};

const getContactBox = (user: User) => {
  switch (user.type) {
    case "person":
      return <PrivateContainer user={user} minimized={false} />;
    case "club":
      return <ClubContainer user={user} minimized={false} />;
    case "company":
      return <EmployeeContainer user={user} />;
    default:
      return <></>;
  }
};

const getProfileBox = (user: User) => {
  switch (user.type) {
    case "person":
      return <PrivateProfileContainer user={user} />;
    case "club":
    case "company":
      return <CompanyAndClubProfileContainer user={user} />;
  }
};

export default ProfileConfig;
