import { NewspaperIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import follow from "@src/api/users/follow";
import unfollow from "@src/api/users/unfollow";
import getUser from "@src/api/users/user";
import ButtonPanel from "@src/components/box/ButtonPanel";
import Panel from "@src/components/box/Panel";
import UserWidget from "@src/components/elements/UserWidget";
import VerifyBadge from "@src/components/elements/VerifyBadge";
import ButtonsBar from "@src/components/elements/form/ButtonsBar";
import HashtagList from "@src/components/elements/hashtags/HashtagList";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import FullScreenImage from "@src/components/elements/shared/FullScreenImage";
import TabLink from "@src/components/elements/shared/tab/TabLink";
import TabMenu from "@src/components/elements/shared/tab/TabMenu";
import Tab from "@src/components/menu/Tab";
import TabFilter from "@src/components/menu/TabFilter";
import BackButton from "@src/components/nav/BackButton";
import PF, { getComponent, SidebarConfigType } from "@src/pages/profile/ProfileConfig";
import Socials from "@src/pages/profile/Socials";
import UserLikeList from "@src/pages/profile/UserLikeList";
import UserRelationList from "@src/pages/profile/UserRelationList";
import EmployeeContainer from "@src/pages/profile/details/EmployeeContainer";
import PrivateStatisticsContainer from "@src/pages/profile/details/PrivateStatisticsContainer";
import ProfileDetailsJobs from "@src/pages/profile/details/ProfileDetailsJobs";
import ProfileDetailsMedia from "@src/pages/profile/details/ProfileDetailsMedia";
import ProfileDetailsPosts from "@src/pages/profile/details/ProfileDetailsPosts";
import ProfileDetailsUser from "@src/pages/profile/details/ProfileDetailsUser";
import ProfileDetailsWatch from "@src/pages/profile/details/ProfileDetailsWatch";
import CVList from "@src/pages/profile/details/cvs/CVList";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import getName from "@src/utils/getName";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

type Props = {
  filter?: string;
};

const ProfileContainer = ({ filter = "about" }: Props) => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const location = useLocation();

  const hashtagTabs = [
    {
      name: "Feed",
      key: "news",
      icon: <NewspaperIcon className="size-4" />,
    },
    {
      name: "Watch",
      key: "watch",
      icon: <VideoCameraIcon className="size-4" />,
    },
  ];

  const [openHeaderImage, setOpenHeaderImage] = useState(false);
  const [hashtagFilter, setHashtagFilter] = useState<string>("news");
  const [sidebarFilter, setSidebarFilter] = useState<string>("");
  const [mediaFilter, setMediaFilter] = useState<string>("news");
  const [showInteractionsList, setShowInteractionsList] = useState<
    "likes" | "feed" | "watch" | "following" | "follows" | "feed_likes" | "watch_likes" | undefined
  >();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const userResult = useQuery({
    queryKey: ["user", `user-${userId}`],
    queryFn: () => getUser({ id: userId || "" }),
    retry: false,
  });

  const userData = userResult.data?.data;

  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const unfollowMutation = useMutation({
    mutationKey: [`unfollow-${userId}`],
    mutationFn: unfollow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const followMutation = useMutation({
    mutationKey: [`follow-${user ? user.id : ""}-${userId}`],
    mutationFn: follow,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  useEffect(() => {
    setSidebarFilter("");
  }, [userId]);

  if (!userResult.isSuccess) return <span>Loading...</span>;

  if (!userData) {
    return;
  }

  if (userData.blocked)
    return (
      <div className="relative">
        <Panel title="Dieser Nutzer ist blockiert" mobileBorder={false}>
          {t("components.shared.blockedUserMessage")}
        </Panel>
        <BackButton stepback={true} className="absolute right-3 top-1 p-[10px]" />
      </div>
    );

  return (
    <>
      <div className="relative">
        <img
          src={
            userData.header_image && userData.header_image !== ""
              ? userData.header_image
              : "/images/default_header_profile.png"
          }
          className="h-[270px] w-full cursor-pointer object-cover max-md:h-[150px]"
          alt={getName(userData)}
          onClick={() => {
            setOpenHeaderImage(true);
          }}
        />
        {!!(openHeaderImage && userData.header_image) && (
          <FullScreenImage
            onClose={() => setOpenHeaderImage(false)}
            imageUrl={userData.header_image}
          />
        )}
        <BackButton
          stepback={true}
          bgColor="bg-white p-0.5"
          className="absolute left-1 top-1 pb-[100px] pr-[40px]"
        />
      </div>
      <Tab stayVisible={true}>
        <TabMenu>
          <div className="flex w-full justify-between gap-2 overflow-x-auto scrollbar-none max-md:mx-3">
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                to={"/profile/" + userId + "/über_mich"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
                active={filter === "about"}
              >
                {userData.type === "person" ? t("main.profileViewTabs.aboutMe.title") : "Über uns"}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                to={"/profile/" + userId + "/news"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
                counter={userData.post_count}
              >
                {t("main.profileViewTabs.feed.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                to={"/profile/" + userId + "/watch"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
                counter={userData.watch_count}
              >
                {t("main.profileViewTabs.watch.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                to={"/profile/" + userId + "/jobs"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
                counter={userData.job_count}
              >
                {t("main.profileViewTabs.jobs.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                to={"/profile/" + userId + "/shop"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
                counter={0}
              >
                {t("main.profileViewTabs.shop.title")}
              </TabLink>
            </div>
            <div
              className="whitespace-nowrap pb-1"
              onClick={() => {
                setSidebarFilter("");
              }}
            >
              <TabLink
                to={"/profile/" + userId + "/inserate"}
                state={{
                  backlink: location.state?.backlink ?? location.pathname,
                }}
                counter={0}
              >
                {t("main.profileViewTabs.advertisements.title")}
              </TabLink>
            </div>
          </div>
        </TabMenu>
      </Tab>

      {/* Main description */}
      <Tab stayVisible={true}>
        <div className="grid grid-flow-row gap-3 max-md:px-3">
          <div className="flex w-full flex-row items-start gap-4">
            <UserWidget avatarFullscreen user={userData} follow={false} online={true} />
            <div className="flex flex-col items-start">
              <div className="grid grid-flow-col items-center gap-1">
                <div className="truncate whitespace-nowrap text-lg font-semibold">
                  {getName(userData)} {userData.blocked && "- Blockiert"}
                </div>
                <VerifyBadge type={userData.type} />
              </div>
              <div className="text-xs">@{userData.username}</div>
            </div>
            {user?.id != userId && (
              <div className="w-[100px] shrink-0 max-lg:ml-auto">
                {userData.followed ? (
                  <Button
                    onClick={() => unfollowMutation.mutate({ id: userId || "" })}
                    size={Button.Sizes.Small}
                    variant={Button.Variants.Transparent}
                  >
                    <img src="/follow.svg" className="h-5 w-4" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => checkAuth() && followMutation.mutate({ id: userId || "" })}
                    size={Button.Sizes.Small}
                    variant={Button.Variants.Red}
                  >
                    {t("main.contacts.tabs.buttonsConsequences")}
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="mt-2 flex flex-row gap-4 text-sm">
            <TabLink
              to={"/profile/" + userId + "/likes"}
              state={{
                backlink: location.state?.backlink ?? location.pathname,
              }}
              active={
                location.pathname.includes("watch_likes") ||
                location.pathname.includes("feed_likes")
              }
            >
              {t("main.likes.title")} {userData.like_count}
            </TabLink>
            <TabLink
              to={"/profile/" + userId + "/follows"}
              state={{
                backlink: location.state?.backlink ?? location.pathname,
              }}
            >
              {t("main.contacts.tabs.followers")} {userData.follower_count}
            </TabLink>
            <TabLink
              to={"/profile/" + userId + "/following"}
              state={{
                backlink: location.state?.backlink ?? location.pathname,
              }}
            >
              {t("main.contacts.tabs.following")} {userData.following_count}
            </TabLink>
          </div>

          <ButtonsBar
            likeButton={{
              id: `${userId}`,
              type: "user",
              liked: userData.liked,
              like_count: userData.like_count,
            }}
            viewButton={{ view_count: 0 }}
            chatButton={{
              id: `${userId}`,
              backlink: location.pathname,
            }}
            connectButton={{ isVisible: userData.type === "person" }}
            shareButton={{
              title: "Profil",
              body: new URL(`/profile/${userId}`, window.location.href).toString(),
            }}
            itemId={userId ?? ""}
            itemType={"user"}
            blocked={userData.blocked}
            editButton={() => {
              navigate("/verwaltung/profile");
            }}
          />
        </div>
      </Tab>

      <Tab isActive={filter === "about"}>
        {/* Über uns Tab */}
        <div className="grid grid-flow-row gap-4">
          <div className="relative flex flex-col gap-2 text-sm">
            {/* Tab control in sidebar */}
            {PF(t)[userData.type].sidebarbuttons &&
              sidebarFilter === "" &&
              !showInteractionsList && (
                <>
                  <div className="mb-2 border-b px-3 pb-1 text-base font-semibold">
                    {PF(t)[userData.type].sidebarbuttons.title}
                  </div>
                  <div className="mx-3 flex flex-wrap gap-x-1 gap-y-2 text-xs">
                    {(PF(t)[userData.type].sidebarbuttons.buttons || [])
                      .filter((item: any) => (item.authRequired ? userId === user?.id : true))
                      .map((button: any, index: number) => {
                        return (
                          <div key={index}>
                            {button.page ? (
                              <NavLink
                                to={button.page}
                                state={{
                                  backlink: location.state?.backlink ?? location.pathname,
                                }}
                              >
                                <img
                                  src={button.image}
                                  className={`mx-auto h-[55px] w-[63px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800 ${button.tabname.localeCompare(sidebarFilter) === 0 && "bg-gray-200"}`}
                                />
                                <div className="text-center text-mini">{button?.label}</div>
                              </NavLink>
                            ) : (
                              <button
                                onClick={() => {
                                  if (button.page)
                                    navigate(button.page, {
                                      state: {
                                        backlink: location.state?.backlink ?? location.pathname,
                                      },
                                    });
                                  setSidebarFilter(button.tabname);
                                }}
                                key={index}
                              >
                                <img
                                  src={button.image}
                                  className={`mx-auto h-[55px] w-[63px] cursor-pointer rounded-lg border border-gray-400 hover:border-gray-800 ${button.tabname.localeCompare(sidebarFilter) === 0 && "bg-gray-200"}`}
                                />
                                <div className="text-center text-mini">{button?.label}</div>
                              </button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}

            {PF(t)[userData.type].sidebars?.map((sidebar: SidebarConfigType) => (
              <Tab key={sidebar.name} isActive={sidebarFilter === sidebar.name}>
                <ButtonPanel onClose={() => setSidebarFilter("")} title={sidebar.title}>
                  {sidebar.items?.map((item: any) => {
                    return (
                      <>
                        {getComponent({
                          component: item,
                          user: userData,
                          t: t,
                        })}
                      </>
                    );
                  })}
                </ButtonPanel>
              </Tab>
            ))}

            <Tab isActive={sidebarFilter === "media"}>
              <ButtonPanel onClose={() => setSidebarFilter("")} title="Medien">
                <TabFilter
                  value={mediaFilter}
                  options={PF(t)[userData.type].mediatabs}
                  onChange={value => setMediaFilter(value)}
                  centered
                />
                {userId && <ProfileDetailsMedia userId={userId} watch={mediaFilter === "watch"} />}
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "hashtags"}>
              <ButtonPanel
                onClose={() => setSidebarFilter("")}
                title={t("main.profileViewTabs.aboutMe.content.sidebarButtons.hashtags")}
              >
                <Tab stayVisible={true}>
                  <TabFilter
                    value={hashtagFilter}
                    options={hashtagTabs}
                    onChange={value => setHashtagFilter(value)}
                    centered
                  />
                </Tab>

                <HashtagList userId={userId} filter={hashtagFilter} />
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "employee"}>
              <ButtonPanel
                onClose={() => setSidebarFilter("")}
                title={t("main.profileViewTabs.editProfile.form.employees")}
              >
                <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                  <EmployeeContainer user={userData} />
                </div>
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "cvs"}>
              <ButtonPanel
                onClose={() => setSidebarFilter("")}
                title={t("main.profileTabs.person.career")}
              >
                <CVList user={userData} />
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "assignments"}>
              <ButtonPanel
                onClose={() => setSidebarFilter("")}
                title={t("main.profileViewTabs.advertisements.title")}
              >
                <div className="grid grid-cols-3 gap-2"></div>
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "social media"}>
              <ButtonPanel
                onClose={() => setSidebarFilter("")}
                title={t("main.profileTabs.person.socialMedia")}
              >
                <div className="grid grid-cols-1 gap-4"></div>
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "members"}>
              <ButtonPanel onClose={() => setSidebarFilter("")} title={t("main.members.title")}>
                <div className="grid grid-cols-1 gap-4"></div>
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "stats"}>
              <ButtonPanel
                onClose={() => setSidebarFilter("")}
                title={t("main.navLinks.createAd.tabs.statistics")}
              >
                <div className="grid grid-cols-1 gap-4">
                  <PrivateStatisticsContainer user={userData} />
                </div>
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "jobs"}>
              <ButtonPanel onClose={() => setSidebarFilter("")} title="Jobs">
                <div className="grid grid-cols-1 gap-4"></div>
              </ButtonPanel>
            </Tab>

            <Tab isActive={sidebarFilter === "social_media"}>
              <ButtonPanel onClose={() => setSidebarFilter("")} title="Social Media">
                <Socials user={userData} />
              </ButtonPanel>
            </Tab>
          </div>
        </div>
      </Tab>

      {["likes", "feed_likes", "watch_likes"].includes(filter ?? "") && userId && (
        <UserLikeList
          type={filter ?? ""}
          userId={userId}
          onClose={() => {
            navigate("/profile/" + userId, {
              state: {
                backlink: location.state?.backlink ?? location.pathname,
              },
            });
          }}
          setTab={(
            e:
              | "likes"
              | "feed"
              | "watch"
              | "following"
              | "follows"
              | "feed_likes"
              | "watch_likes"
              | undefined,
          ) => setShowInteractionsList(e)}
        />
      )}

      {(filter === "following" || filter === "follows") && userId && (
        <UserRelationList
          type={filter}
          userId={userId}
          onClose={() => {
            navigate("/profile/" + userId, {
              state: {
                backlink: location.state?.backlink ?? location.pathname,
              },
            });
          }}
        />
      )}

      {filter === "news" && userId && <ProfileDetailsPosts userId={userId} />}

      <Tab isActive={sidebarFilter === "personal"}>
        <ButtonPanel
          onClose={() => {
            setSidebarFilter("");
          }}
          title={t("main.profileViewTabs.editProfile.form.moreInformation.personal")}
        >
          <div className="grid grid-cols-1 gap-4">
            <ProfileDetailsUser userId={userId ?? ""} />
          </div>
        </ButtonPanel>
      </Tab>

      <Tab isActive={filter === "watch"}>
        <div className="grid grid-cols-1 gap-4">
          <ProfileDetailsWatch userId={userId} showInput={true} />
        </div>
      </Tab>

      <Tab isActive={filter === "ads"}>
        <div className="grid grid-cols-1 gap-4">
          <Input placeholder={t("components.shared.searchShop")} name="shop_keywords" clearable />
        </div>
      </Tab>

      <Tab isActive={filter === "jobs"}>
        <div className="grid grid-cols-1 gap-4">
          <ProfileDetailsJobs userId={userId} />
        </div>
      </Tab>

      <Tab isActive={filter === "assignments"}>
        <div className="grid grid-cols-1 gap-4">
          <Input
            placeholder={t("components.input.searchAdd")}
            name="assignments_keywords"
            clearable
          />
        </div>
      </Tab>
    </>
  );
};

export default ProfileContainer;
