import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import post from "@src/api/posts/post";
import update from "@src/api/posts/update";
import LegacyLinkPreview from "@src/components/elements/LegacyLinkPreview";
import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";
import Checkbox from "@src/components/elements/input/Checkbox";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import EmojiButton from "@src/components/elements/shared/buttons/EmojiButton";
import PostMediaWatch from "@src/pages/watch/PostMediaWatch";
import WatchTextEdit from "@src/pages/watch/WatchTextEdit";
import { Post } from "@src/utils/post";
import regex from "@src/utils/regex";
import { MediaType } from "@src/utils/types/MediaType";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
  postId: string;
};
type PropsInner = {
  onSuccess: () => void;
  onClose: () => void;
  post: Post;
};

const EditWatchPostInner = ({ onSuccess, onClose, post }: PropsInner) => {
  const { t } = useTranslation();
  const [link, setLink] = useState<string | undefined>(post.link);
  const [adult, setAdult] = useState(false);
  const [linkPreviewId, setLinkPreviewId] = useState<string>();
  const [text, setText] = useState(post.text);
  const [emojiOpen, setEmojiOpen] = useState(false);

  const [media, setMedia] = useState<Array<MediaType>>(
    post.media &&
      post.media.map(m => ({
        id: m.id,
        type: m.type,
        thumbnail: m.thumbnail,
        file_name: m.file_name,
        key: m.key,
        data_url: "",
      })),
  );
  const queryClient = useQueryClient();

  const links = regex.url.exec(text);
  if (links && links[0] && !link) {
    setLink(links[0]);
    setText(value => value.replace(links[0], ""));
  }

  const updateMutation = useMutation({
    mutationKey: ["post"],
    mutationFn: update,
    onSuccess: () => {
      setText("");
      setAdult(false);
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      onSuccess();
    },
    onError: () => {
      alert("Failed");
    },
  });

  return (
    <PopupWrapper onClose={onClose}>
      <div className="grid grid-flow-row gap-2">
        <div className="text-base font-semibold">{t("main.watch.create.edit")}</div>
        <PostUserSelect />

        {link && (
          <LegacyLinkPreview
            link={link}
            onChange={setLinkPreviewId}
            onDelete={() => {
              setLink(undefined);
              setLinkPreviewId(undefined);
            }}
          />
        )}

        <div className="flex h-[300px] flex-col gap-1">
          <WatchTextEdit
            value={text}
            onChange={setText}
            videoUrl={media && media.length > 0 ? media[0].data_url : ""}
          />
        </div>

        <PostMediaWatch onChange={data => setMedia([data])} media={media ?? []} />

        <div className="flex flex-row items-baseline justify-between">
          <Checkbox name="18+" checked={adult} onChange={checked => setAdult(checked)}>
            {t("main.watch.create.adult")}
          </Checkbox>
          <div className="max-sm:hidden">
            <EmojiButton onClick={() => setEmojiOpen(value => !value)} />
          </div>

          <div className="w-fit">
            <Button
              onClick={() =>
                updateMutation.mutate({
                  id: post.id,
                  data: {
                    text,
                    adult,
                    media: media.map(m => m.id),
                    link_preview_id: linkPreviewId,
                    link,
                    watch: true,
                  },
                })
              }
            >
              <PaperAirplaneIcon className="h-[20px]" />
            </Button>
          </div>
        </div>
        {emojiOpen && (
          <EmojiPicker
            onEmojiClick={emoji =>
              setText(data => data + String.fromCodePoint(parseInt(emoji.unified, 16)))
            }
          />
        )}
      </div>
    </PopupWrapper>
  );
};

const EditWatchPost = ({ onSuccess, postId, onClose }: Props) => {
  const postResult = useQuery({
    queryKey: ["post", postId],
    queryFn: () => post({ id: postId }),
  });

  if (postResult.isPending) {
    return <div>Loading...</div>;
  }

  if (postResult.error || !postResult.data) {
    return <div>Error fetching post</div>;
  }

  return <EditWatchPostInner onSuccess={onSuccess} onClose={onClose} post={postResult.data} />;
};

export default EditWatchPost;
