import { ArrowUpOnSquareIcon, HeartIcon, PlayIcon } from "@heroicons/react/24/outline";
import PostGallery from "@src/components/elements/shared/PostGallery";
import isMobile from "@src/utils/isMobile";
import { MediaType } from "@src/utils/types/MediaType";
import { useState } from "react";

type Props = {
  images: Array<MediaType>;
  likeButton?: () => void;
  shareButton?: () => void;
};

const Video = ({ media }: { media: MediaType }) => (
  <div className="relative size-full">
    <img
      src={`${media.data_url}/thumbnails/thumbnail.jpg?height=600&width=600&fit=crop`}
      className="size-full object-cover"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/placeholder.png";
      }}
    />
    <PlayIcon className="absolute left-1/2 top-1/2 size-16 -translate-x-1/2 -translate-y-1/2 text-white" />
  </div>
);

const Image = ({ media }: { media: MediaType }) => (
  <img src={`${media.data_url}`} className="size-full cursor-pointer rounded-lg object-cover" />
);

const Overlay = ({
  likeButton,
  shareButton,
}: {
  likeButton?: () => void;
  shareButton?: () => void;
}) => (
  <>
    {likeButton && (
      <button className="absolute bottom-2 left-4 rounded-full bg-white xl:opacity-0">
        <HeartIcon className="size-6" />
      </button>
    )}
    {shareButton && (
      <button className="absolute right-4 top-2 rounded-full bg-white xl:opacity-0">
        <ArrowUpOnSquareIcon className="size-6" />
      </button>
    )}
  </>
);

const ImageGrid = ({ images, ...props }: Props) => {
  const [selection, setSelection] = useState(0);

  if (!isMobile)
    return (
      <div className="flex flex-col gap-2 max-xl:flex-col-reverse xl:flex-row">
        <div className="flex flex-row gap-1 xl:flex-col">
          {images.map((image, index) => (
            <button
              key={index}
              className={`rounded-lg bg-gray-300/80 p-[2px] hover:bg-teal-500 ${selection === index && "w-fit bg-teal-500/40"}`}
              onClick={() => {
                setSelection(index);
              }}
            >
              <div className="size-[44px] cursor-pointer overflow-hidden rounded-lg border border-gray-400">
                {image.type === "image" && <Image media={image} />}
                {image.type === "video" && <Video media={image} />}
              </div>
            </button>
          ))}
        </div>
        <div className="group relative h-[300px] w-full">
          <div className="relative size-full cursor-pointer max-xl:aspect-video">
            {images[selection].type === "image" && <Image media={images[selection]} />}
            {images[selection].type === "video" && <Video media={images[selection]} />}
          </div>
          <Overlay likeButton={props.likeButton} shareButton={props.shareButton} />
        </div>
      </div>
    );
  if (isMobile)
    return (
      <>
        <PostGallery
          onClick={() => {
            return;
          }}
          media={images}
          hideBorder
          hideMargin
        >
          <Overlay likeButton={props.likeButton} shareButton={props.shareButton} />
        </PostGallery>
      </>
    );
};

export default ImageGrid;
