import FullScreenImage from "@src/components/elements/shared/FullScreenImage";
import usePopupModal from "@src/state/modal/usePopupModal";
import isApp from "@src/utils/isApp";
import { useTranslation } from "react-i18next";

const AppQRCode = () => {
  const popupModal = usePopupModal();
  const { t } = useTranslation();
  if (isApp) return <></>;

  return (
    <>
      <div className="flex flex-col items-center gap-2">
        <div className="text-xs">{t("components.posts.scanQR")}</div>
        <button
          onClick={() =>
            popupModal.open(
              <FullScreenImage
                imageUrl="/images/app/qr_code.svg"
                onClose={popupModal.close}
                imageCSS="w-[300px] h-[300px]"
                backgroundCSS="bg-opacity-70"
              />,
            )
          }
        >
          <img src="/images/app/qr_code.svg" className="size-[100px]" />
        </button>
      </div>
    </>
  );
};

export default AppQRCode;
