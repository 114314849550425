import { XMarkIcon } from "@heroicons/react/24/outline";
import Panel from "@src/components/box/Panel";
import ContactsEditor from "@src/components/elements/form/ContactsEditor";
import HashMapList from "@src/components/elements/form/HashMapList";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import Select from "@src/components/elements/input/Select";
import Tags from "@src/components/elements/input/Tags";
import PostUserSelect from "@src/components/elements/posts/form/shared/PostUserSelect";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import { useAppSelector } from "@src/state/hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onSuccess: () => void;
  onClose: () => void;
};

function GroupsCreateContainer({ onClose }: Props) {
  const [tab, setTab] = useState<string>("basics");
  const { t } = useTranslation();
  const statusOptions = [
    { name: t("main.header.links.groups.status.open"), value: "0" },
    { name: t("main.header.links.groups.status.closed"), value: "1" },
    { name: t("main.header.links.groups.status.privat"), value: "2" },
  ];

  const tabs = [
    {
      label: t("main.header.links.groups.tabs.info"),
      key: "basics",
    },
    {
      label: t("main.header.links.groups.tabs.description"),
      key: "details",
    },
    {
      label: t("main.header.links.groups.tabs.contact"),
      key: "contact",
    },
    {
      label: t("main.header.links.groups.tabs.socialMedia"),
      key: "social",
    },
  ];

  const startSiteOptions = [
    { name: t("components.assignments.groupscreate.newsfeed"), value: "news" },
    {
      name: t("components.assignments.groupscreate.marketplace"),
      value: "marketplace",
    },
    { name: t("components.assignments.groupscreate.watch"), value: "watch" },
    {
      name: t("components.assignments.groupscreate.inserate"),
      value: "inserate",
    },
    {
      name: t("components.assignments.groupscreate.messanger"),
      value: "messenger",
    },
    {
      name: t("components.assignments.groupscreate.contacts"),
      value: "contacts",
    },
    { name: t("components.assignments.groupscreate.groups"), value: "groups" },
    { name: t("components.assignments.groupscreate.jobs"), value: "jobs" },
  ];

  const socialMediaSites = [
    { name: t("main.signIn.external.facebook"), value: "facebook" },
    { name: t("main.signIn.external.instagram"), value: "instagram" },
    { name: t("main.signIn.external.youtube"), value: "youtube" },
    { name: t("main.signIn.external.xing"), value: "xing" },
    { name: t("main.signIn.external.linkedin"), value: "linkedIn" },
    { name: t("main.signIn.external.twitter"), value: "twitter" },
  ];

  const { user } = useAppSelector(state => state.user);
  if (!user) return <span>{t("main.loading")}</span>;

  /**
   * Tab switching
   * @param direction
   */
  const changeTab = (direction: number) => {
    const tabIndex =
      tabs.findIndex(element => {
        return element.key === tab;
      }) + direction;
    if (typeof tabs[tabIndex] != "undefined") {
      setTab(tabs[tabIndex].key);
    }
  };

  const TabControl = () => (
    <div className="flex flex-row flex-wrap justify-end sm:flex-nowrap sm:gap-4">
      <Button
        variant={Button.Variants.Transparent}
        size={Button.Sizes.Small}
        onClick={() => changeTab(-1)}
      >
        {t("main.groupsInfoForm.buttons.back")}
      </Button>
      <Button
        variant={Button.Variants.Transparent}
        size={Button.Sizes.Small}
        onClick={() => changeTab(1)}
      >
        {t("main.groupsInfoForm.buttons.further")}
      </Button>
      <Button
        variant={Button.Variants.Transparent}
        size={Button.Sizes.Small}
        onClick={() => {
          return;
        }}
      >
        {t("main.groupsInfoForm.buttons.preview")}
      </Button>
      <Button
        size={Button.Sizes.Small}
        onClick={() => {
          return;
        }}
      >
        {t("main.groupsInfoForm.buttons.save")}
      </Button>
    </div>
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <button onClick={onClose}>
        <XMarkIcon className="size-5 text-gray-700" />
      </button>
      <TabController isSubTab isPersistent defaultTab={tab}>
        <TabHeaders headerCSS="mb-2">
          {tabs.map(tab => {
            return (
              <TabHeader id={tab.key} key={tab.key}>
                {tab.label}
              </TabHeader>
            );
          })}
        </TabHeaders>
        <TabBodies>
          <TabBody id="basics">
            <Panel mobileBorder={false}>
              <div className="grid grid-flow-row gap-2 text-sm">
                <PostUserSelect />
                <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
                  <Input
                    name="groupname"
                    placeholder={t("main.groupsInfoForm.groupName")}
                    label={t("main.groupsInfoForm.groupName")}
                  />
                </div>
                <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
                  <Input
                    name="country"
                    placeholder={t("main.groupsInfoForm.country")}
                    label={t("main.groupsInfoForm.country")}
                  />
                  <Input
                    name="city"
                    placeholder={t("main.groupsInfoForm.zipCity")}
                    label={t("main.groupsInfoForm.zipCity")}
                  />
                </div>
                <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
                  <Input
                    name="phone"
                    placeholder={t("main.groupsInfoForm.phone")}
                    label={t("main.groupsInfoForm.phone")}
                  />
                  <Input
                    name="fax"
                    placeholder={t("main.groupsInfoForm.fax")}
                    label={t("main.groupsInfoForm.fax")}
                  />
                </div>
                <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
                  <Input
                    name="website"
                    placeholder={t("main.groupsInfoForm.website")}
                    label={t("main.groupsInfoForm.website")}
                  />
                  <Input
                    name="email"
                    placeholder={t("main.groupsInfoForm.email")}
                    label={t("main.groupsInfoForm.email")}
                  />
                </div>
                <div className="grid grid-flow-row items-center gap-2 lg:grid-cols-2">
                  <Select
                    items={statusOptions}
                    name="status"
                    label={t("main.groupsInfoForm.groupType")}
                  />
                  <Select
                    items={startSiteOptions}
                    name="startsite"
                    label={t("main.groupsInfoForm.homePage")}
                  />
                </div>
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
                  <Input
                    type="file"
                    name="profileimage"
                    label={t("main.groupsInfoForm.uploadProfilePicture")}
                  />
                  <Input
                    type="file"
                    name="headerimage"
                    label={t("main.groupsInfoForm.updateHeaderImage")}
                  />
                </div>
              </div>
              <TabControl />
            </Panel>
          </TabBody>
          <TabBody id="details">
            <Panel mobileBorder={false}>
              <div className="grid grid-flow-row gap-x-4 gap-y-2">
                <div className="text-sm">
                  {t("main.groupsInfoForm.description")}
                  <textarea
                    name="description"
                    className="block w-full rounded-xl border-0 bg-gray-200 px-4 py-3"
                  />
                </div>
                <Input
                  name="specialization"
                  placeholder={t("main.groupsInfoForm.shortDescription")}
                />
                <Input
                  name="shortdescription"
                  placeholder={t("main.groupsInfoForm.shortDescription")}
                />
                <Tags
                  label={t("main.groupsInfoForm.industry")}
                  name="branch"
                  placeholder={t("main.groupsInfoForm.industry")}
                />

                <Tags
                  label={t("main.groupsInfoForm.keywords")}
                  name="keywords"
                  placeholder={t("main.groupsInfoForm.keywords")}
                />
              </div>
            </Panel>
          </TabBody>
          <TabBody id="contact">
            <Panel mobileBorder={false}>
              <div className="my-2 font-semibold">
                {t("main.groupsInfoForm.supervisorGroupsManager")}
              </div>
              <ContactsEditor
                onChange={() => {
                  return;
                }}
              />
              <TabControl />
            </Panel>
          </TabBody>
          <TabBody id="social">
            <Panel mobileBorder={false}>
              <HashMapList
                name="socialmedia"
                onChange={() => {
                  return;
                }}
                types={socialMediaSites}
                values={[]}
                placeholder={t("components.input.link")}
              />
              <TabControl />
            </Panel>
          </TabBody>
        </TabBodies>
      </TabController>
    </form>
  );
}

export default GroupsCreateContainer;
