import { UserAvatar } from "@src/components/elements/UserWidget";
import { useAppSelector } from "@src/state/hooks";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import getName from "@src/utils/getName";
import { User } from "@src/utils/user";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  user: User;
  follow?: boolean;
  showName?: boolean;
  sideName?: boolean;
  online?: boolean;
  size?: number;
  verified?: boolean;
  key?: string;
  nameStyle?: string;
  avatarFullscreen?: boolean;
};

const UserWidgetPicture = ({
  user,
  showName = false,
  follow = true,
  online = true,
  verified = false,
  nameStyle,
  ...props
}: Props) => {
  const { user: userData } = useAppSelector(state => state.user);
  const self = userData && userData.id === user.id;
  const followed = self || !follow || user.followed || false;
  online = online && true;
  const size = props.size || 75;
  const placeholder = `/images/placeholder/${user.type}.png`;
  const containerRef = useRef<HTMLAnchorElement>(null);
  const userBubbleModal = useUserBubbleModal();

  const placeholderHeaderImage = "/images/placeholder/headerImage.png";
  const [openAvatar, setOpenAvatar] = useState(false);

  const handleMouseOver = () => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      userBubbleModal.open({
        position: { x: rect.x, y: rect.y },
        offset: { x: 0, y: 100 },
        user,
      });
    }
  };

  return (
    <NavLink
      to={`/profile/${user.id}`}
      onClick={e => {
        userBubbleModal.close();
        if (props.avatarFullscreen) {
          setOpenAvatar(!openAvatar);
          e.preventDefault();
        }
      }}
      onMouseOver={handleMouseOver}
      onMouseLeave={() => {
        userBubbleModal.close();
      }}
      ref={containerRef}
    >
      <img
        className="max-h-[75px] min-h-[75px] w-full rounded-t-lg object-cover"
        src={user.header_image || placeholderHeaderImage}
        alt={getName(user)}
      />
      <div
        className={`relative top-[-40px] w-full cursor-pointer ${props.sideName && "flex"} ${props.avatarFullscreen && "pointer-events-auto"}`}
      >
        <UserAvatar
          followed={followed}
          online={online}
          openAvatar={openAvatar}
          setOpenAvatar={() => setOpenAvatar(false)}
          placeholder={placeholder}
          sideName={props.sideName}
          size={size}
          user={user}
          userData={userData}
          verified={verified}
          showName={showName}
          nameStyle={nameStyle}
        />
      </div>
    </NavLink>
  );
};

export default UserWidgetPicture;
