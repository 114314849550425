import { XMarkIcon } from "@heroicons/react/24/outline";
import mediaUpload from "@src/api/media/upload";
import videoUpload from "@src/api/video/upload/direct";
import Button from "@src/components/elements/input/Button";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTus } from "use-tus";

type Props = {
  onChange: (media: {
    key: string;
    id: string;
    file_name: string;
    type: string;
    thumbnail: string;
    data_url?: string;
  }) => void;
  onDelete: (id: string) => void;
  media: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail: string;
    data_url?: string;
  }>;
  fileLimit?: number;
  strictLimit?: boolean;
  filetypes?: string;
};
const AdMedia = ({
  onChange,
  media,
  onDelete,
  fileLimit = 10,
  strictLimit,
  filetypes = "image/*,video/*,application/pdf",
}: Props) => {
  const [uploadingCount, setUploadingCount] = useState(0);
  const { upload, setUpload, isSuccess, remove } = useTus();

  useEffect(() => {
    if (upload) {
      upload.start();
    }
  }, [upload]);

  useEffect(() => {
    if (isSuccess) {
      remove();
    }
  }, [isSuccess, remove]);

  const { t } = useTranslation();

  const uploadMutation = useMutation({
    mutationKey: ["upload"],
    mutationFn: async (file: File) => {
      if (!file) {
        throw new Error("No file provided");
      }
      let data_url = "";
      let key = "";
      let id = "";
      let endpoint = "";
      let thumbnail = "";
      let tus_headers = {};
      if (file.type.startsWith("video")) {
        if (upload) {
          throw new Error("Already uploading");
        }
        const { data } = await videoUpload({ params: { videoNames: file.name } });
        endpoint = data.endpoint;
        tus_headers = data.tus_headers;
        key = data.key;
        id = data.id;
        thumbnail = data.thumbnail;
        if (!file) {
          return;
        }
        setUpload(file, {
          endpoint: endpoint,
          headers: tus_headers,
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
        });
      } else {
        const { data } = await mediaUpload({ file });
        key = data.key;
        id = data.id;
        data_url = data.data_url;
      }

      if (
        !key ||
        !id ||
        !file.name ||
        (file.type.startsWith("video") && !thumbnail) ||
        (!file.type.startsWith("video") && !data_url) ||
        !file.type
      ) {
        throw new Error("Invalid response");
      }
      return {
        key,
        id,
        file_name: file.name,
        type: file.type.startsWith("video") ? "video" : "image",
        thumbnail,
        data_url,
      };
    },
    onSuccess: data => {
      setUploadingCount(count => count - 1);
      if (!data) {
        return;
      }
      onChange(data);
    },
    onError: () => {
      setUploadingCount(count => count - 1);
      alert("Failed");
    },
  });

  const fileInputRef = React.createRef<HTMLInputElement>();
  return (
    <>
      <div className="text-sm font-semibold">{t("addMedia.media")}</div>
      <div className="text-sm">
        {!strictLimit && (
          <>
            {fileLimit != 1
              ? `${t("addMedia.upTo")} ${fileLimit} ${t("addMedia.filesToUpload")}`
              : t("addMedia.onlyOneFile")}
          </>
        )}
        {strictLimit && (
          <>
            {fileLimit != 1
              ? `${t("addMedia.exact")} ${fileLimit} ${t("addMedia.filesNeeded")}`
              : t("addMedia.onlyOneFile")}
          </>
        )}
      </div>
      <div className="grid grid-cols-4 gap-3 rounded-xl border p-3">
        {media.map(data => (
          <div key={data.id} className="relative">
            <button
              onClick={() => {
                onDelete(data.id);
              }}
              className="absolute right-3 top-3"
            >
              <XMarkIcon className="size-6 rounded-full bg-white opacity-90" />
            </button>
            {data.type === "image" && (
              <img className="rounded-xl bg-gray-200" src={data.data_url} />
            )}
            {data.type === "video" && (
              <img className="rounded-xl bg-gray-200" src={data.thumbnail} />
            )}
          </div>
        ))}
        {[...Array(uploadingCount)].map((_, index) => (
          <div key={index} className="rounded-xl bg-gray-200">
            <svg className="mr-3 size-5 animate-spin" viewBox="0 0 24 24"></svg>
          </div>
        ))}
      </div>
      <div className="ml-auto w-fit">
        <Button
          size={Button.Sizes.Small}
          variant={Button.Variants.Transparent}
          onClick={() => {
            if (fileInputRef.current) fileInputRef.current.click();
          }}
        >
          {t("addMedia.uploadMedia")}
        </Button>
      </div>
      <input
        type="file"
        className="ml-auto"
        accept={filetypes}
        onChange={e => {
          if (e.target.files && e.target.files.length + uploadingCount + media.length > fileLimit) {
            alert("Max " + fileLimit + " Dateien");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount(count => count + 1);
            uploadMutation.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
    </>
  );
};

export default AdMedia;
