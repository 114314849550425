import { PostsResponse } from "@src/api/hashtags/posts";
import { CommentType } from "@src/utils/types/CommentType";
import { LinkPreviewType } from "@src/utils/types/LinkPreviewType";
import { MediaType } from "@src/utils/types/MediaType";
import { User } from "@src/utils/user";
import { DefinedInfiniteQueryObserverResult, InfiniteData } from "@tanstack/react-query";
import { SetStateAction } from "react";

export type Post = {
  id: string;
  text: string;
  author: User;
  created_at: string;
  comments: Array<CommentType>;
  media: Array<MediaType>;
  liked: boolean;
  like_count: number;
  comment_count: number;
  view_count: number;
  bookmark_count: number;
  repost_count: number;
  is_reposted: boolean;
  bookmarked: boolean;
  link: string;
  watch: boolean;
  legacy: boolean;
  link_preview: LinkPreviewType;
  reposted: Post;
};

export function prevPost({
  index,
  pageIndex,
  postsResult,
}: {
  index: number;
  pageIndex: number;
  postsResult: DefinedInfiniteQueryObserverResult<InfiniteData<PostsResponse>>;
}) {
  if (index == 0 && pageIndex > 0)
    return postsResult.data.pages[pageIndex - 1].data[
      postsResult.data.pages[pageIndex - 1].data.length - 1
    ].id;
  if (index > 0) return postsResult.data.pages[pageIndex].data[index - 1].id;
  return "";
}

export function nextPost({
  index,
  pageIndex,
  postsResult,
}: {
  index: number;
  pageIndex: number;
  postsResult: DefinedInfiniteQueryObserverResult<InfiniteData<PostsResponse>>;
}) {
  if (
    pageIndex + 1 < postsResult.data.pages.length &&
    index + 1 == postsResult.data.pages[pageIndex].data.length
  )
    return postsResult.data.pages[pageIndex + 1].data[0].id;
  if (index + 1 < postsResult.data.pages[pageIndex].data.length)
    return postsResult.data.pages[pageIndex].data[index + 1].id;
  return "";
}

export const removeLinkFromLexicalObject = (lexicalObjectRaw: string, link: string) => {
  try {
    if (!link) return lexicalObjectRaw;

    const lexicalObject = JSON.parse(lexicalObjectRaw);
    if (lexicalObject["root"]["children"].length === 0) return lexicalObjectRaw;
    if (lexicalObject["root"]["children"][0]["children"].length === 0) return lexicalObjectRaw;

    for (const element of lexicalObject["root"]["children"]) {
      if (element["children"].length === 0) continue;
      if (
        element["children"][0]["type"] === "link" ||
        element["children"][0]["type"] === "autolink"
      ) {
        if (element["children"][0]["url"] === link) {
          element["children"].shift();
          break;
        }
      }
    }
    return JSON.stringify(lexicalObject);
  } catch {
    return lexicalObjectRaw;
  }
};

export function editTheme(placeholder: string) {
  return {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: placeholder,
    paragraph: "editor-paragraph",
    quote: "editor-quote",
    hashtag: "editor-link",
    heading: {
      h1: "editor-heading-h1",
      h2: "editor-heading-h2",
      h3: "editor-heading-h3",
      h4: "editor-heading-h4",
      h5: "editor-heading-h5",
    },
    list: {
      nested: {
        listitem: "editor-nested-listitem",
      },
      ol: "editor-list-ol",
      ul: "editor-list-ul",
      listitem: "editor-listitem",
    },
    image: "editor-image",
    link: "editor-link",
    text: {
      bold: "editor-text-bold",
      italic: "editor-text-italic",
      overflowed: "editor-text-overflowed",
      hashtag: "editor-text-hashtag",
      underline: "editor-text-underline",
      strikethrough: "editor-text-strikethrough",
      underlineStrikethrough: "editor-text-underlineStrikethrough",
      code: "editor-text-code",
    },
    code: "editor-code",
    codeHighlight: {
      atrule: "editor-tokenAttr",
      attr: "editor-tokenAttr",
      boolean: "editor-tokenProperty",
      builtin: "editor-tokenSelector",
      cdata: "editor-tokenComment",
      char: "editor-tokenSelector",
      class: "editor-tokenFunction",
      "class-name": "editor-tokenFunction",
      comment: "editor-tokenComment",
      constant: "editor-tokenProperty",
      deleted: "editor-tokenProperty",
      doctype: "editor-tokenComment",
      entity: "editor-tokenOperator",
      function: "editor-tokenFunction",
      important: "editor-tokenVariable",
      inserted: "editor-tokenSelector",
      keyword: "editor-tokenAttr",
      namespace: "editor-tokenVariable",
      number: "editor-tokenProperty",
      operator: "editor-tokenOperator",
      prolog: "editor-tokenComment",
      property: "editor-tokenProperty",
      punctuation: "editor-tokenPunctuation",
      regex: "editor-tokenVariable",
      selector: "editor-tokenSelector",
      string: "editor-tokenSelector",
      symbol: "editor-tokenProperty",
      tag: "editor-tokenProperty",
      url: "editor-tokenOperator",
      variable: "editor-tokenVariable",
    },
  };
}

export function resizeHandler(
  viewPort: VisualViewport | null,
  setBottomPadding: (value: SetStateAction<number>) => void,
  setInitHeight: (value: SetStateAction<number>) => void,
) {
  if (window.visualViewport && viewPort) {
    let iosHeight = 0;
    if (!/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
      iosHeight = viewPort.height;
      setInitHeight(viewPort.height);
    }
    setBottomPadding(Math.abs(iosHeight - viewPort.height));
  }
}

export function blurHandler(setBottomPadding: (value: SetStateAction<number>) => void) {
  setBottomPadding(0);
}
