import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon, UsersIcon } from "@heroicons/react/20/solid";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import isApp from "@src/utils/isApp";
import React, { ReactNode, useRef, useState } from "react";

type Props = {
  items: Array<
    | {
        label: string;
        icon: React.FC<Parameters<typeof UsersIcon>[0]>;
        onClick: () => void;
      }
    | null
    | false
    | undefined
  >;
  showCircle?: boolean;
  icon?: ReactNode;
};

const ContextMenu = (props: Props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  if (isApp || window.innerWidth < 1280) {
    return (
      <>
        <button
          className="flex items-center rounded-full text-gray-400 hover:text-gray-600"
          onClick={e => {
            setOpen(true);
            e.stopPropagation();
          }}
        >
          <button className="size-5" aria-hidden="true">
            {props.icon ? props.icon : <EllipsisVerticalIcon />}
          </button>
        </button>
        <ModalWrapper open={open} onCancel={() => setOpen(false)} hideActions={true}>
          {props.items.map(
            (item, index) =>
              !!item && (
                <button
                  key={index}
                  onClick={e => {
                    item.onClick();
                    setOpen(false);
                    e.stopPropagation();
                  }}
                  type="submit"
                  className="inline-flex w-full items-center justify-between py-2 text-left text-sm text-gray-700"
                >
                  <span>{item.label}</span>
                  <item.icon className="size-4" />
                </button>
              ),
          )}
        </ModalWrapper>
      </>
    );
  } else {
    return (
      <>
        <Menu as="div" className="relative inline-block w-full text-left" ref={ref}>
          <Menu.Button
            className={`flex items-center rounded-full text-gray-400 hover:text-gray-600 ${
              props.showCircle &&
              "rounded-full opacity-0 group-hover/contextmenu:bg-white group-hover/contextmenu:opacity-80"
            }`}
            onClick={e => e.stopPropagation()}
          >
            {props.icon ? (
              props.icon
            ) : (
              <EllipsisVerticalIcon className="size-5 opacity-100" aria-hidden="true" />
            )}
          </Menu.Button>

          <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
            <div className="py-1">
              {props.items.map(
                (item, index) =>
                  !!item && (
                    <div
                      className="mx-1 rounded-md text-gray-700 hover:bg-gray-200 hover:text-gray-900"
                      key={index}
                    >
                      <button
                        key={index}
                        onClick={e => {
                          item.onClick();
                          e.stopPropagation();
                        }}
                        type="submit"
                        className="inline-flex w-full items-center justify-between p-2 text-left text-sm"
                      >
                        <span>{item.label}</span>
                        <item.icon className="size-4" />
                      </button>
                    </div>
                  ),
              )}
            </div>
          </Menu.Items>
        </Menu>
      </>
    );
  }
};

export default ContextMenu;
