import {
  EnvelopeIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  PlusCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import NavSideMenuElement from "@src/components/nav/NavSideMenuElement";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import isApp from "@src/utils/isApp";
import { NavLink, useLocation } from "react-router-dom";

type Props = {
  dark?: boolean;
};

const NavBarBottom = (props: Props) => {
  const location = useLocation();
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);

  const createForms: { [index: string]: { url: string } } = {};

  const searchForms: Array<string> = [];

  const newsfeedIcon: Array<string> = [];
  const watchIcon: Array<string> = [];

  const path = location.pathname.split("/")[1];
  const createPath = "/" + path + (createForms[path]?.url ?? "");

  const baseOpacity = props.dark ? "opacity-100" : "opacity-60";

  return (
    <div
      className={`fixed inset-x-0 bottom-0 z-30 my-auto grid h-[40px] w-full grid-flow-col items-center justify-around pb-2 pt-1 align-middle text-darkblue xl:hidden ${isApp && "bottom-[env(safe-area-inset-bottom)]"} ${props.dark && "bg-black text-white"}`}
    >
      <NavLink to="/" className="flex size-full justify-center">
        <img src="/logo512.png" className="size-7 rounded-md" alt="Logo" />
      </NavLink>
      <NavLink
        className="group flex size-full justify-center"
        to="/messenger"
        onClick={e => {
          if (!user) {
            e.preventDefault();
            loginModal.open();
          }
        }}
      >
        {({ isActive }) => (
          <EnvelopeIcon
            className={`size-7 group-hover:opacity-100 ${isActive && !props.dark ? "border-t-2 border-darkblue" : baseOpacity}`}
          />
        )}
      </NavLink>
      {createForms[path] && (
        <NavLink
          to={createPath}
          state={{ tab: "create" }}
          className="flex size-full justify-center"
        >
          <PlusCircleIcon
            className="size-8"
            onClick={e => {
              if (!user) {
                e.preventDefault();
                loginModal.open();
              }
            }}
          />
        </NavLink>
      )}
      {searchForms.includes(path) && (
        <NavLink to="/search" className="flex size-full justify-center">
          <MagnifyingGlassIcon className="size-7" />
        </NavLink>
      )}
      {!newsfeedIcon.includes(path) && (
        <NavLink to={"/news"} className="group flex size-full justify-center">
          {({ isActive }) => (
            <NewspaperIcon
              className={`size-7 group-hover:opacity-100 ${isActive && !props.dark ? "border-t-2 border-darkblue" : baseOpacity}`}
            />
          )}
        </NavLink>
      )}
      {!watchIcon.includes(path) && (
        <NavLink to={"/watch"} className="group flex size-full justify-center">
          {({ isActive }) => (
            <VideoCameraIcon
              className={`size-7 group-hover:opacity-100 ${isActive && !props.dark ? "border-t-2 border-darkblue" : baseOpacity}`}
            />
          )}
        </NavLink>
      )}
      <NavSideMenuElement />
    </div>
  );
};

export default NavBarBottom;
