import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  name: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  required?: boolean;
  key?: string;
  dark?: boolean;
  title?: string;
  label?: string;
};
const Checkbox = ({ children, name, onChange, checked, required, dark, title }: Props) => {
  return (
    <div className="relative mt-1 flex items-start" title={title}>
      <div className="flex h-5 items-center">
        <input
          id={name}
          name={name}
          onChange={() => onChange(!checked)}
          checked={checked}
          type="checkbox"
          className="size-4 rounded border-gray-300 text-darkblue focus:ring-darkblue focus:ring-offset-0 invalid:focus:ring-tf-red-600"
          required={required}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className={`font-medium text-gray-700 ${dark && "text-white"}`}>
          {children}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
