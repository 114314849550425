import update from "@src/api/chats/groups/update";
import upload from "@src/api/media/upload";
import GroupComboBox from "@src/components/elements/form/GroupComboBox";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import ModalWrapper from "@src/components/elements/shared/ModalWrapper";
import AvatarEditElement from "@src/pages/messenger/AvatarEditElement";
import getName from "@src/utils/getName";
import { MediaType } from "@src/utils/types/MediaType";
import { User } from "@src/utils/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  chatId: string;
  name: string;
  visible: boolean;
  members: Array<User>;
  closeModal: () => void;
  avatar?: MediaType;
};

const EditModal = ({ visible, closeModal, members, name, chatId, avatar }: Props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(name ?? "");
  const [memberEdit, setMemberEdit] = useState(false);
  const [groupMembers, setGroupMembers] = useState(members);
  const imageRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<MediaType | undefined>(avatar);
  const queryClient = useQueryClient();

  const uploadMutation = useMutation({
    mutationKey: ["upload"],
    mutationFn: upload,
    onSuccess: ({ data }) => setImage(data),
    onError: () => {
      alert("Failed");
    },
  });

  const updateMutation = useMutation({
    mutationKey: ["chats", "groups", "update"],
    mutationFn: update,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["chats"],
      });
      closeModal();
      setTimeout(() => {
        updateMutation.reset();
      }, 500);
    },
    onError: () => {
      setTimeout(() => {
        updateMutation.reset();
      }, 500);
    },
  });

  return (
    <ModalWrapper
      open={visible}
      onCancel={closeModal}
      onConfirm={() =>
        updateMutation.mutate({
          id: chatId,
          data: {
            name: value,
            members: groupMembers.map(item => item.id),
            avatar: image?.id,
          },
        })
      }
      loading={updateMutation.isPending}
      failed={updateMutation.isError}
      hideActions={memberEdit}
      title={t("pages.messenger.editGroup")}
    >
      <div className="no-scrollbar grid max-h-[75vh] grid-cols-1 gap-4 overflow-y-scroll">
        {!memberEdit ? (
          <div>
            <input
              type="file"
              className="hidden"
              ref={imageRef}
              multiple={false}
              onChange={e => {
                if (e.target.files) {
                  uploadMutation.mutate({ file: e.target.files[0] });
                }
              }}
            />
            <AvatarEditElement
              image={image}
              loading={uploadMutation.isPending}
              onClick={() => imageRef.current?.click()}
            />
            <Input value={value} onChange={val => setValue(val)} name="Gruppe erstellen" />
            <div className="mt-2 grid grid-cols-1 gap-2">
              <div>{t("pages.messenger.participate")}</div>
              {groupMembers.map(member => (
                <div
                  key={member.id}
                  className="relative cursor-pointer select-none rounded-2xl border border-gray-300 py-1.5 pl-3 pr-9 text-gray-900 shadow-sm hover:border-darkblue"
                >
                  <div className="flex items-center">
                    <img
                      src={
                        (member.avatar !== "" && member.avatar) ||
                        `/images/placeholder/${member.type}.png`
                      }
                      alt=""
                      className="size-6 shrink-0 rounded-full"
                    />
                    <span className="ml-3 line-clamp-1 text-left">{getName(member)}</span>
                  </div>
                </div>
              ))}
              <Button variant={Button.Variants.Transparent} onClick={() => setMemberEdit(true)}>
                {t("main.profileViewTabs.aboutMe.content.sidebarButtons.edit")}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <GroupComboBox
              selectedUsers={groupMembers}
              setSelectedUsers={setGroupMembers}
              placeholder={t("main.header.links.messenger.searchBar")}
            />
          </>
        )}
      </div>
      {memberEdit && (
        <Button variant={Button.Variants.Transparent} onClick={() => setMemberEdit(false)}>
          {t("pages.messenger.done")}
        </Button>
      )}
    </ModalWrapper>
  );
};

export default EditModal;
