import Checkbox from "@src/components/elements/input/Checkbox";
import Input from "@src/components/elements/input/Input";

type Props = {
  items: Array<{
    checked?: boolean;
    label?: string;
    title?: string;
  }>;
  label?: string;
  name: string;
  placeholder?: string;
  itemStyle?: string;
  onChange?: (
    e: Array<{
      checked?: boolean;
      label?: string;
    }>,
  ) => void;
};

const CheckboxGroup = ({
  items = [],
  label,
  name,
  onChange,
  itemStyle = "flex flex-col lg:flex-row gap-x-8 gap-y-0",
}: Props) => {
  return (
    <div className="grid grid-flow-row">
      <div className="text-sm">{label}</div>
      <div className={itemStyle}>
        {items.map((box, index) => {
          return (
            <Checkbox
              key={name + "-" + index}
              checked={box.checked ?? false}
              name={name + "-" + index}
              title={box.title}
              onChange={e => {
                const newItems = [...items];
                newItems[index].checked = e;
                if (onChange) onChange(newItems);
              }}
            >
              {box.label}
            </Checkbox>
          );
        })}
        <Input name={name} type="hidden" value={JSON.stringify(items)} placeholder="" />
      </div>
    </div>
  );
};

export default CheckboxGroup;
