import { UserCircleIcon } from "@heroicons/react/24/outline";
import MyProfileWidget from "@src/components/elements/MyProfileWidget";
import { useAppSelector } from "@src/state/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavSideMenuElement = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const location = useLocation();
  const placeholder = `/images/placeholder/${user ? user.type : ""}.png`;
  const navigate = useNavigate();

  const closeMenu = () => {
    setSideMenu(false);
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <div className="bg-transparent">
      {sideMenu && (
        <div className="fixed inset-y-0 left-0 z-50 w-full bg-white px-4 pb-6 pt-[calc(env(safe-area-inset-top)+0.5rem)] text-black xl:hidden">
          <MyProfileWidget isMobile={true} onClose={closeMenu} />
        </div>
      )}
      <div className="relative flex cursor-pointer flex-col items-center py-0">
        {!user && (
          <button
            className="group relative flex cursor-pointer flex-col items-center py-0"
            onClick={() => navigate("/login")}
          >
            <UserCircleIcon className="heroicon-stroke-w-1.2 size-7" />
          </button>
        )}
        {user && (
          <button
            className="flex size-full justify-center text-darkblue"
            onClick={() => {
              setSideMenu(value => !value);
            }}
          >
            <div className="relative size-[28px] shrink-0 rounded-full border border-gray-300 bg-white">
              <div className="flex size-full items-center justify-center overflow-hidden rounded-full">
                <img className="w-full" src={user ? user.avatar : placeholder} />
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default NavSideMenuElement;
