import comments from "@src/api/posts/comments";
import ShowMoreButton from "@src/components/elements/input/ShowMoreButton";
import CreatePostComment from "@src/components/elements/posts/comments/CreatePostComment";
import PostComment from "@src/components/elements/posts/comments/PostComment";
import { useAppSelector } from "@src/state/hooks";
import { CommentType } from "@src/utils/types/CommentType";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

type Props = {
  postId: string;
  viewCount?: number;
  hideCreateComment?: boolean;
};

const PostCommentSection = ({ postId, ...props }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const self = !!user;
  const [_answer, setAnswer] = useState<CommentType | undefined>();

  const commentsResult = useInfiniteQuery({
    queryKey: ["comments", postId],
    queryFn: ({ pageParam }) => comments({ id: postId, params: { page: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!commentsResult.isSuccess) return <></>;

  return (
    <div
      className={`grid grid-cols-1 gap-3 text-left ${self && !props.hideCreateComment && "mt-4"} ${commentsResult.data.pages[0] && commentsResult.data.pages[0].data.length > 0 && "mt-4"}`}
    >
      {self && !props.hideCreateComment && (
        <CreatePostComment postId={postId} onSuccess={() => setAnswer(undefined)} />
      )}
      {commentsResult.data.pages.map(page =>
        page.data.map(
          comment =>
            !comment.parent_id && (
              <PostComment
                viewCount={props.viewCount}
                comments={page.data}
                level={0}
                postId={postId}
                key={comment.id}
                comment={comment}
              />
            ),
        ),
      )}
      {(commentsResult.hasNextPage || commentsResult.isFetchingNextPage) && (
        <ShowMoreButton onClick={() => commentsResult.fetchNextPage()} />
      )}
    </div>
  );
};

export default PostCommentSection;
